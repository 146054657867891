@import url(https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 微軟正黑體;
  /* border: 1px solid; */
  box-sizing: border-box;
}

#root {
  width: 100%;
}


html {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: auto;
  overflow-x:hidden;
}

body {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.loading {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: #eee;
  background: white;
  justify-content: center;
  align-items: center;
  z-index: 120;
  bottom: 0;
  right: 0;
}
:root {
  --light-blue: #ccfefb;
  --pink: #c47c85;
}

.fileInputContainer {
  width: 100%;
  background: #fdd8dc;
  display: flex;
  flex-direction: column;
  color: #606060;
  align-items: center;
  padding-bottom: 70px;
}

.fileInputTitle {
  font-size: 3rem;
  min-width: 200px;
}

.textArea {
  min-width: 200px;
  box-sizing: border-box;
  padding: 10px;
}

.gray {
  background: #606060;
}

.optionContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.optionButton {
  border: none;
  background: none;
  border-bottom: 6px solid #ce848d;
  margin: 15px;
  color: #606060;
  font-size: 1rem;
  outline: none;
  min-width: 120px;
}

.optionActivated {
  border: 6px solid #ce848d;
  background: none;
  margin: 15px;
  padding: 10px 20px 10px 20px;
  color: #606060;
  font-size: 1rem;
  outline: none;
  min-width: 180px;
}

.optionButton:hover {
  cursor: pointer;
}

.fileSelectImage {
  -webkit-filter: invert(0.3);
          filter: invert(0.3);
  transition: 0.3s;
  margin: 30px;
}

.fileSelectImage:hover {
  cursor: pointer;
  -webkit-filter: invert(0);
          filter: invert(0);
  transition: 0.3s;
}

.fileSelectContainer > input {
  visibility: hidden;
}

.fileInputMethod {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
}

.optionDescription {
  font-size: 1rem;
}

.descriptionContainer {
  margin-right: 20px;
  min-width: 200px;
}

.submitButton {
  background: #ccfefb;
  background: var(--light-blue);
  position: relative;
  top: 30px;
  border: none;
  padding: 10px;
  border-radius: 20px;
  width: 223px;
  height: 60px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  transition: 0.3s;
}

.submitButton:hover {
  cursor: pointer;
  background: #a7f8f3;
  transition: 0.3s;
}

@media (max-width: 700px) {
  .fileInputMethod {
    width: auto;
    flex-wrap: wrap;
  }
}

:root {
  --light-blue: #ccfefb;
  --pink: #e7929c;
}

/* @font-face {
    font-family: 'SourceHanSerifTC-SemiBold';
    src: local('SourceHanSerifTC-SemiBold'), url('./font/SourceHanSerifTC-SemiBold.otf');
} */

@font-face {
  font-family: "Scaramella-Regular";
  src: local("Scaramella-Regular"), url(/static/media/Scaramella-Regular.918231d3.otf);
}

.previewContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleContainer {
  margin-bottom: 60px;
}

.previewDescriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 20px;
  width: 50%;
}

.preview {
  border: 2px solid #eee;
  box-shadow: 10px 10px 10px #eee;
}

.editorContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.editorOption {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 40px;
}

.option {
  margin: 15px;
  display: flex;
  flex-direction: column;
}

.black {
  background: black;
}

.buttonContainer {
  margin: 50px;
}

.previewDownloadButton {
  border: none;
  border-bottom: 10px solid #ccfefb;
  border-bottom: 10px solid var(--light-blue);
  background: none;
  font-size: 1.5rem;
  margin: 20px;
  transition: 0.3s;
}

.previewDownloadButton:hover {
  cursor: pointer;
  border-color: #a7f8f3;
  transition: 0.3s;
}

.downloadButton {
  border: none;
  border-radius: 10px;
  font-size: 1.5rem;
  padding: 10px 15px 10px 15px;
  background: #ccfefb;
  background: var(--light-blue);
  margin: 20px;
  transition: 0.3s;
}

.downloadButton:hover {
  cursor: pointer;
  background: #a7f8f3;
  transition: 0.3s;
}

@media only screen and (max-width: 700px) {
  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.designOptionOuterContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 150px;
  background-color: #e7929c;
}

.designOptionRow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 70px;
}

.designOptionContainer {
  width: 12%;
  min-width: 130px;
  position: relative;
  z-index: 3;
  background: #f8adb6;
  padding: 13px;
  transition: 0.3s;
  margin: 15px;
}

.designActivated {
  background: #fa7484;
  transition: 0.3s;
}

.designOptionContainer:hover {
  cursor: pointer;
}

.designOption {
  width: 100%;
}

.titleContainer {
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.designOptionTitle {
  color: white;
  font-size: 3rem;
}

.designOptionDescription {
  color: white;
  margin: 0px 50px;
  z-index: 100;
}

.bottomLine {
  width: 195px;
  height: 10px;
  position: absolute;
  top: 105px;
}

.white {
  background: white;
}

.pinkBackground {
  position: absolute;
  width: 100%;
  top: -150px;
  z-index: 1;
}

@media (max-width: 488px) {
  .designOptionRow {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .designOptionContainer {
    width: 12%;
    min-width: 100px;
    position: relative;
    z-index: 3;
    background: #f8adb6;
    padding: 8px;
    transition: 0.3s;
    margin: 5px;
  }
}

.footerContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact {
  font-size: 1rem;
  position: relative;
  top: 450px;
  z-index: 2;
}

.grayBackground {
  width: 100%;
  position: relative;
  top: 30px;
  z-index: 1;
}

@media (max-width: 960px) {
  .contact {
    font-size: 1rem;
    position: relative;
    top: 300px;
    z-index: 2;
  }
}

@media (max-width: 620px) {
  .contact {
    font-size: 1rem;
    position: relative;
    top: 250px;
    z-index: 2;
  }
}

@media (max-width: 488px) {
  .grayBackground {
    width: 100%;
    height: 250px;
    position: relative;
    top: 30px;
    z-index: 1;
  }
  .contact {
    font-size: 1rem;
    margin: 10px;
    position: relative;
    top: 280px;
    z-index: 2;
    text-align: center;
  }
}

:root {
  --light-blue: #ccfefb;
  --pink: #e7929c;
}

.outerContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 70px;
}

.innerContainer {
}

.imageContainer {
  width: 500px;
  position: relative;
  margin-left: 20px;
}

.titleContainer {
  position: relative;
}

.headerImage {
  width: 100%;
  position: relative;
  z-index: 10;
}

.title {
  z-index: 2;
  position: relative;
  margin-left: 10px;
  font-size: 4rem;
}

.small {
  width: 257px;
  height: 40px;
  top: 90px;
  z-index: 1;
}

.description {
  font-size: 1.5rem;
}

.pinkBlock {
  background: #e7929c;
  background: var(--pink);
  position: absolute;
}

.big {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 1;
}

.startMakingButton {
  border: none;
  background: #ccfefb;
  background: var(--light-blue);
  padding: 10px;
  border-radius: 20px;
  width: 223px;
  height: 60px;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  transition: 0.3s;
  margin-top: 30px;
}

.startMakingButton:hover {
  cursor: pointer;
  background: #a7f8f3;
  transition: 0.3s;
}

@media (max-width: 620px) {
  .outerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    font-size: 3rem;
  }
  .description {
    font-size: 1rem;
  }
  .imageContainer {
    display: none;
  }
  .small {
    width: 200px;
    height: 40px;
    top: 60px;
    z-index: 1;
  }
}

