.footerContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact {
  font-size: 1rem;
  position: relative;
  top: 450px;
  z-index: 2;
}

.grayBackground {
  width: 100%;
  position: relative;
  top: 30px;
  z-index: 1;
}

@media (max-width: 960px) {
  .contact {
    font-size: 1rem;
    position: relative;
    top: 300px;
    z-index: 2;
  }
}

@media (max-width: 620px) {
  .contact {
    font-size: 1rem;
    position: relative;
    top: 250px;
    z-index: 2;
  }
}

@media (max-width: 488px) {
  .grayBackground {
    width: 100%;
    height: 250px;
    position: relative;
    top: 30px;
    z-index: 1;
  }
  .contact {
    font-size: 1rem;
    margin: 10px;
    position: relative;
    top: 280px;
    z-index: 2;
    text-align: center;
  }
}
