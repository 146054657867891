@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@600;700&display=swap");

:root {
  --light-blue: #ccfefb;
  --pink: #e7929c;
}

/* @font-face {
    font-family: 'SourceHanSerifTC-SemiBold';
    src: local('SourceHanSerifTC-SemiBold'), url('./font/SourceHanSerifTC-SemiBold.otf');
} */

@font-face {
  font-family: "Scaramella-Regular";
  src: local("Scaramella-Regular"), url("./font/Scaramella-Regular.otf");
}

.previewContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleContainer {
  margin-bottom: 60px;
}

.previewDescriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 20px;
  width: 50%;
}

.preview {
  border: 2px solid #eee;
  box-shadow: 10px 10px 10px #eee;
}

.editorContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.editorOption {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 40px;
}

.option {
  margin: 15px;
  display: flex;
  flex-direction: column;
}

.black {
  background: black;
}

.buttonContainer {
  margin: 50px;
}

.previewDownloadButton {
  border: none;
  border-bottom: 10px solid var(--light-blue);
  background: none;
  font-size: 1.5rem;
  margin: 20px;
  transition: 0.3s;
}

.previewDownloadButton:hover {
  cursor: pointer;
  border-color: #a7f8f3;
  transition: 0.3s;
}

.downloadButton {
  border: none;
  border-radius: 10px;
  font-size: 1.5rem;
  padding: 10px 15px 10px 15px;
  background: var(--light-blue);
  margin: 20px;
  transition: 0.3s;
}

.downloadButton:hover {
  cursor: pointer;
  background: #a7f8f3;
  transition: 0.3s;
}

@media only screen and (max-width: 700px) {
  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
