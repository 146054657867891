:root {
  --light-blue: #ccfefb;
  --pink: #e7929c;
}

.outerContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 70px;
}

.innerContainer {
}

.imageContainer {
  width: 500px;
  position: relative;
  margin-left: 20px;
}

.titleContainer {
  position: relative;
}

.headerImage {
  width: 100%;
  position: relative;
  z-index: 10;
}

.title {
  z-index: 2;
  position: relative;
  margin-left: 10px;
  font-size: 4rem;
}

.small {
  width: 257px;
  height: 40px;
  top: 90px;
  z-index: 1;
}

.description {
  font-size: 1.5rem;
}

.pinkBlock {
  background: var(--pink);
  position: absolute;
}

.big {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 1;
}

.startMakingButton {
  border: none;
  background: var(--light-blue);
  padding: 10px;
  border-radius: 20px;
  width: 223px;
  height: 60px;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  transition: 0.3s;
  margin-top: 30px;
}

.startMakingButton:hover {
  cursor: pointer;
  background: #a7f8f3;
  transition: 0.3s;
}

@media (max-width: 620px) {
  .outerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    font-size: 3rem;
  }
  .description {
    font-size: 1rem;
  }
  .imageContainer {
    display: none;
  }
  .small {
    width: 200px;
    height: 40px;
    top: 60px;
    z-index: 1;
  }
}
