:root {
  --light-blue: #ccfefb;
  --pink: #c47c85;
}

.fileInputContainer {
  width: 100%;
  background: #fdd8dc;
  display: flex;
  flex-direction: column;
  color: #606060;
  align-items: center;
  padding-bottom: 70px;
}

.fileInputTitle {
  font-size: 3rem;
  min-width: 200px;
}

.textArea {
  min-width: 200px;
  box-sizing: border-box;
  padding: 10px;
}

.gray {
  background: #606060;
}

.optionContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.optionButton {
  border: none;
  background: none;
  border-bottom: 6px solid #ce848d;
  margin: 15px;
  color: #606060;
  font-size: 1rem;
  outline: none;
  min-width: 120px;
}

.optionActivated {
  border: 6px solid #ce848d;
  background: none;
  margin: 15px;
  padding: 10px 20px 10px 20px;
  color: #606060;
  font-size: 1rem;
  outline: none;
  min-width: 180px;
}

.optionButton:hover {
  cursor: pointer;
}

.fileSelectImage {
  filter: invert(0.3);
  transition: 0.3s;
  margin: 30px;
}

.fileSelectImage:hover {
  cursor: pointer;
  filter: invert(0);
  transition: 0.3s;
}

.fileSelectContainer > input {
  visibility: hidden;
}

.fileInputMethod {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
}

.optionDescription {
  font-size: 1rem;
}

.descriptionContainer {
  margin-right: 20px;
  min-width: 200px;
}

.submitButton {
  background: var(--light-blue);
  position: relative;
  top: 30px;
  border: none;
  padding: 10px;
  border-radius: 20px;
  width: 223px;
  height: 60px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  transition: 0.3s;
}

.submitButton:hover {
  cursor: pointer;
  background: #a7f8f3;
  transition: 0.3s;
}

@media (max-width: 700px) {
  .fileInputMethod {
    width: auto;
    flex-wrap: wrap;
  }
}
