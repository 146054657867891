.designOptionOuterContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 150px;
  background-color: #e7929c;
}

.designOptionRow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 70px;
}

.designOptionContainer {
  width: 12%;
  min-width: 130px;
  position: relative;
  z-index: 3;
  background: #f8adb6;
  padding: 13px;
  transition: 0.3s;
  margin: 15px;
}

.designActivated {
  background: #fa7484;
  transition: 0.3s;
}

.designOptionContainer:hover {
  cursor: pointer;
}

.designOption {
  width: 100%;
}

.titleContainer {
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.designOptionTitle {
  color: white;
  font-size: 3rem;
}

.designOptionDescription {
  color: white;
  margin: 0px 50px;
  z-index: 100;
}

.bottomLine {
  width: 195px;
  height: 10px;
  position: absolute;
  top: 105px;
}

.white {
  background: white;
}

.pinkBackground {
  position: absolute;
  width: 100%;
  top: -150px;
  z-index: 1;
}

@media (max-width: 488px) {
  .designOptionRow {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .designOptionContainer {
    width: 12%;
    min-width: 100px;
    position: relative;
    z-index: 3;
    background: #f8adb6;
    padding: 8px;
    transition: 0.3s;
    margin: 5px;
  }
}
