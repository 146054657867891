* {
  font-family: 微軟正黑體;
  /* border: 1px solid; */
  box-sizing: border-box;
}

#root {
  width: 100%;
}


html {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: auto;
  overflow-x:hidden;
}

body {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.loading {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: #eee;
  background: white;
  justify-content: center;
  align-items: center;
  z-index: 120;
  bottom: 0;
  right: 0;
}